body {
	overflow-x: hidden;
}
.background {
	position: absolute;
	top: 0;
	height: 0;
	width: 100%;
	height: 100%;
	background-image: url('../img/bg_working.jpg');
	background-size: cover;
	background-position: center center;
	z-index: 0;
	filter: opacity(50%);
	z-index: -5;
}
.presentation{
	height:34rem;
}
.login button:hover{
	color: white !important;
}
.custom-bg-white {
	background-color: rgba(255, 255, 255, .9);
}

.center-11-12 {
	margin-left: 4%;
}

@media (min-width: 768px) { 
	.center-11-12 {
		margin-left: 33%;
	}
}

/**********************************************/
.topnav {
	/*overflow: hidden;*/
	background-color: #000;
	/*background-color: #333;*/
}
  
.topnav a {
	float: left;
	display: block;
	color: #F2F2F2;
	text-align: center;
	padding: 14px 10px;
	text-decoration: none;
	font-size: 14px;
}
  
.topnav a:hover {
	background-color: #DDD;
	color: black;
}
  
.topnav a.active {
	background-color: #CE4C4C;
	color: white;
}
  
/*.topnav.icon {
	display: none;
}
  
@media screen and (max-width: 600px) {
	.topnav a:not(:first-child) {display: none;}
	.topnav a.icon {
	  float: right;
	  display: block;
	}
}
  
@media screen and (max-width: 600px) {
	.topnav.responsive {position: relative;}
	.topnav.responsive .icon {
	  position: absolute;
	  right: 0;
	  top: 0;
	}
	.topnav.responsive a {
	  float: none;
	  display: block;
	  text-align: left;
	}
}*/

/**********************************************/
.hiringTopbg{
	background-image: url('../img/banner1.jpg');
	background-position: center center;
	background-size: cover;
	color: #DDD;
	/*opacity: 0.5;*/
}
.hiringstep{
	background-color: #FFFFFF!important;
	color: #0C3C60;
}

/**********************************************/
.aboutTopbg{
	background-image: url('../img/banner2.jpg');
	background-position: center center;
	background-size: cover;
	color: #DDD;
	/*opacity: 0.5;*/
}

/**********************************************/
.contactTopbg{
	background-image: url('../img/banner3.jpg');
	background-position: center center;
	background-size: cover;
	color: #DDD;
	/*opacity: 0.5;*/
}

/* Styles for CKEditor and Vacancies */

.ck.ck-content ul,
.ck.ck-content ul li{
  list-style-type: inherit;
}

.ck.ck-content ul, .ck.ck-content ol, .vacancies ol, .vacancies ul {
	padding-left: 20px;
}
.ck.ck-content ol,
.ck.ck-content ol li, .vacancies ol li{
  list-style-type: decimal;
}
.ck-file-dialog-button, .ck-toolbar__items .ck.ck-dropdown{
	display: none;
}
.vacancies ul li{
	list-style-type: initial;
}
.vacancies blockquote{
	overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: 5px solid #ccc;
}

/* Paginator */

.rc-pagination {
	float: right;
	font-size: 14px;
	font-weight: bold;
}
.rc-pagination-item {	
	margin-right: 4px;	
}
