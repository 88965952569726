@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); 

@tailwind base;

.defaultTable {
   @apply w-full;
   @apply mt-10;
	@apply rounded-t-lg;
	@apply shadow;
	@apply mb-10;
}
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.defaultTable th {
   @apply bg-black;
   @apply text-white;
   @apply text-left;
   @apply font-normal;
   @apply px-3;
   @apply py-4;
   @apply font-poppins;
   @apply text-xs;
   @apply border-black;
}

.defaultTable tr:nth-child(odd) {
	@apply bg-gray-50;
	@apply border;
	@apply font-poppins;
	@apply text-sm;
	@apply border-gray-200;
}

.defaultTable tr:nth-child(even) {
	@apply border;
	@apply border-gray-200;
	@apply bg-white;
	@apply font-poppins;
	@apply text-sm;

}

.defaultTable tbody tr:hover {
	@apply bg-blue-50;
}

.defaultTable td {
	@apply px-3;
	@apply py-3
}

.btn_options {
	@apply mr-2;
	@apply text-gray-400;
	@apply text-lg;
}
.btn_options:hover {
	@apply text-blue-900;
}
.btn_options:focus {
	@apply outline-none;
}

.userInfoTable {

}
.userInfoTable th {
	@apply border-b;
	@apply text-left;
	@apply text-gray-400
}
.userInfoTable td {
	@apply border-b;
	@apply text-left;
	@apply py-2;
}
.userInfoTable tr:last-child .userInfoTable td {
	@apply border-0;
}

.btn_edit {
	@apply mr-2;
	@apply text-yellow-400;
	@apply text-lg;
}
.btn_edit:hover {
	@apply text-yellow-700;
}
.btn_edit:focus {
	@apply outline-none;
}

.btn_delete {
	@apply mr-2;
	@apply text-red-400;
	@apply text-lg;
}
.btn_delete:hover {
	@apply text-red-700;
}
.btn_delete:focus {
	@apply outline-none;
}

.btn_play {
	@apply mr-2;
	@apply text-green-400;
	@apply text-lg;
}
.btn_play:hover {
	@apply text-green-700;
}
.btn_play:focus {
	@apply outline-none;
}

@tailwind components;
@tailwind utilities;
